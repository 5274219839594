import React from "react";
import StandardPageTemplate from "../../../templates/standard-page";
import PageWrapper from "Components/core/PageWrapper";
import { Link } from "gatsby";
import * as Text from "Components/text";

import Heading from "Components/Heading";
import FeatureTable from "Components/FeatureTable";
import PriceBlockSmall from "Components/PriceBlockSmall";
import BrainImg from "Images/index-company-pillars.svg";
import AmyleeSphere from "Images/amylee-amos-sphere.png";
import BrainIcon from "Images/brain-white.svg";

const PAGE_PROPS = {
  title: "A Program to Prevent Alzheimer’s",
  desc:
    "Our program is designed to empower you with the tools necessary to enact the most promising treatment for Alzheimer's disease and dementia in your life, or the life of your loved one.",
};

const LP_Prevention_Page = () => {
  return (
    <StandardPageTemplate props={PAGE_PROPS}>
      <PageWrapper>
        <Heading
          text="A Program to Prevent Alzheimer’s"
          subtitle="Introducing"
        />
        <Text.P>
          A common question we’re frequently asked is whether or not Alzheimer’s
          disease can be prevented. Usually, those who ask this question are
          either individuals who know someone who has suffered, or are
          suffering, from this disease or they, themselves, have found out they
          carry the ApoE4 gene (a genetic risk factor for Alzheimer’s disease).
          Whichever the case, science shows that having Alzheimer’s disease
          doesn’t have to be your genetic destiny. By starting now, you can
          build a lifestyle that will lead you to your best health outcomes.
        </Text.P>
        <div className="w-auto md:w-5/12 shadow-lg rounded-lg pl-8 pr-4 py-4 mb-12 flex flex-row items-center">
          <div>
            <img className="w-24" src={AmyleeSphere} alt="" />
          </div>
          <div className="pl-4">
            <span className="text-base text-gray-500 block mb-0">
              Amylee Amos PhD, RDN, IFMCP
            </span>
            <span className="text-sm text-purple-800 block mb-0">
              Founder, Amos Institute
            </span>
          </div>
        </div>
        <Text.H2>Our Cognitive Health Program</Text.H2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Text.P>
              The Amos Institute has transformed the latest scientific research
              for fighting and preventing cognitive diseases into a
              comprehensive program you can implement from your home. This
              program is designed to empower you with the methods, tools, and
              information necessary to enact the most promising treatments for
              Alzheimer’s disease and dementia into your life or the life of a
              loved one.
            </Text.P>
            <Text.P>
              Coupled with support from our team of highly trained nutrition and
              health specialists, our Cognitive Health Program is not only
              highly impactful, but it’s the most accessible program of its
              kind. From day one, you’ll start making significant changes to
              your lifestyle, putting you on the path toward your best possible
              health outcomes.
            </Text.P>
          </div>
          <div>
            <img
              className="mx-auto pt-0 lg:pt-8 w-80 lg:w-auto"
              src={BrainImg}
              alt=""
            />
          </div>
        </div>

        <Text.H2>Life Changing Results</Text.H2>
        <Text.P>
          Built upon{" "}
          <Link to={"/what-is-the-bredesen-protocol/"}>
            extensive evidence-based research
          </Link>
          , our Cognitive Health Program is a therapeutic, personalized program
          that identifies, targets, and treats the root cause of the cognitive
          decline from Alzheimer’s disease. Not only does our Cognitive Health
          Program improve markers of cognitive function including memory,
          attention, and alertness, but our clients also experience improvements
          in inflammatory status, reduced oxidative stress, improved glycemic
          control, and reduced total toxic load.{" "}
        </Text.P>
        <div className="w-auto md:w-8/12 mx-auto shadow-lg rounded-lg pl-8 pr-4 py-4 mb-12 flex flex-row items-center bg-purple-800">
          <div>
            <img className="sm:w-24 w-40" src={BrainIcon} alt="" />
          </div>
          <div className="pl-6">
            <span className="text-base block mb-0 text-white">
              Results of a recent clinical trial show that{" "}
              <strong className="text-white text-lg">84%</strong> of patients
              exhibit improvement in their cognition when following the
              methodologies implimented through our program.
            </span>
          </div>
        </div>
        <Text.P>
          By using the Bredesen Protocol, multiple personalized strategies, such
          as changes in nutrition, targeted supplementation, sleep hygiene
          optimization, stress management, physical exercise, brain training,
          and related modalities are used to improve cognition and reverse the
          cognitive decline and Alzheimer’s disease. In other words, instead of
          masking symptoms (like prescription drugs do), our program addresses
          the root cause of the disease. This approach has been highly effective
          and has been used in over thirteen countries, including throughout the
          United States.
        </Text.P>
        <FeatureTable />
        <Text.H2>The Best Time to Start is Right Now</Text.H2>
        <Text.P>
          Our Cognitive Health Program was designed to provide immediate aid for
          anyone who is dealing with or trying to prevent Alzheimer's Disease
          and Dementia as well as anyone who is taking care of a loved one with
          these diseases. The cost of Alzheimer's disease can be massive, but
          it's the Amos Institute's mission to provide services that are
          affordable and accessible for everyone while being capable of
          transformative results. It only takes a few minutes to get started,
          signup now and begin your journey to better cognitive health.
        </Text.P>
        <PriceBlockSmall />
      </PageWrapper>
    </StandardPageTemplate>
  );
};

export default LP_Prevention_Page;
